import './privacy.css';
import React, { useState,useEffect } from 'react';
import Container from '@mui/material/Container';
import { WEB_SITE_NAME, WEB_SITE_URL } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { postPrivacy } from '../../redux/auth/action';
import { toast } from 'react-toastify';
import { POST_VERIFY_EMAIL } from '../../redux/actionTypes';
import {logo_icon_black, logo_letters_black, img_verification} from '../../images';
import { useParams } from 'react-router-dom';
import GridLoader from "react-spinners/GridLoader";
import TermsEn from './terms-en';
import TermsEl from './terms-el';
import TermsDe from './terms-de';
import TermsFr from './terms-fr';
import TermsIt from './terms-it';



const Privacy = (props) => {

  //Then inside your component
  const { lang,fromApp } = useParams();
  const dispatch = useDispatch();
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const trans = settingsData.trans;


  const buildPrivacyUI = () => {
    return TermsEl();
    var selectedLang = "en";

    console.log("lang",lang);
      // if (lang != null && lang != ""){
      //   selectedLang = lang;
      // } 

      // if (selectedLang == "en"){
      //   return TermsEn();
      // }
     
      // if (selectedLang == "el"){
      //   return TermsEl();
      // }

      // if (selectedLang == "de"){
      //   return TermsDe();
      // }

      // if (selectedLang == "fr"){
      //   return TermsFr();
      // }

      // if (selectedLang == "it"){
      //   return TermsIt();
      // }

    return (<div></div>);
  }

  return (
      <Container component="main">


        <div style={{width: '100%'}}>
          <div>

            {fromApp == 1 ? (<div style={{height: '20px'}}></div>) : (<div>
              
            </div>)}
            
          </div>

          <div>
            {buildPrivacyUI()}
          </div>

        </div>
    
      </Container>
  );
}

export default Privacy;