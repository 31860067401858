import {
  POST_USERS_ALL,
  POST_USERS_ADD,
  POST_USERS_EDIT,
  POST_USERS_CHANGE_APPROVED
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  usersData: null,
  addUserData: null,
  editUserData: null,
  changeUserApprovedData: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_USERS_ALL:
               return {
                ...state,
               usersData: action.payload
          }
          case POST_USERS_ADD:
            return {
              ...state,
              addUserData: action.payload
          }
          case POST_USERS_EDIT:
            return {
              ...state,
              editUserData: action.payload
          }
          case POST_USERS_CHANGE_APPROVED:
            return {
              ...state,
              changeUserApprovedData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;