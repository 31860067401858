import {
  POST_UPLOAD_IMAGE,
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  uploadImageData: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_UPLOAD_IMAGE:
               return {
                ...state,
                uploadImageData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;