import {combineReducers} from 'redux';


import settings from './settings/reducer';
import auth from './auth/reducer';
import categories from './categories/reducer';
import sources from './sources/reducer';
import upload from './upload/reducer';
import tags from './tags/reducer';
import posts from './posts/reducer';
import socialtemplates from './socialtemplate/reducer';
import users from './users/reducer';

export default combineReducers({
    settings: settings,
    auth: auth,
    categories: categories,
    sources: sources,
    upload: upload,
    tags: tags,
    posts: posts,
    socialtemplates: socialtemplates,
    users: users
  });
