import React, { useState,useEffect } from 'react';
import { Navigate, Outlet,Redirect } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { POST_USER_INFO, USER_ID_SET, USER_TOKEN_SET } from '../redux/actionTypes';
import { getSettings, getCountries } from '../redux/settings/action';
import Loading from './loading';
import * as General from '../helpers/General'
import * as Store from '../helpers/Store';
import { postUserInfo } from '../redux/auth/action';
import axios from '../helpers/Api'


const PrivateRoute = (props) => {

   // console.log("PRIVATEROUTING",props);
    

     //BASIC LOGIC IS 1. GET SETTINGS 2. CHECK IF LOGGED IN 3. RETURN LOADER || HOME || LOGIN 

    const dispatch = useDispatch();



    const [countriesLoaded,setCountriesLoaded] = useState(false)
    const [settingsLoaded,setSettingsLoaded] = useState(false)
    const [userDataLoaded,setUserDataLoaded] = useState(false);
    const [userAuth,setUserAuth] = useState(false);

    const userInfoData = useSelector(({auth}) => auth.userInfoData);
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
   
    useEffect(() => { //ONLOAD
        //LETS GET THE TRANSLATIONS FOR LATER USE
        setSettingsLoaded(false);
        setUserDataLoaded(false);
        dispatch(getSettings()); 
      }, []);

    
    useEffect(() => {
        //SETTINGS LOADED.
        //IF THE USER IS LOGGED IN THEN WE NEED TO GET THE USER DATA AND POP HIM TO HOME PAGE
        //OTHERWISE WE NEED TO SHOW THE LOGIN PAGE
        if (settingsData !== null) {
          if (General.isLoggedIn()){
              setSettingsLoaded(true); 
              dispatch(getCountries()); 
             // getUserData();
          }  else {
            setSettingsLoaded(true); 
            dispatch(getCountries()); 
          }
        } 
    }, [settingsData]);

    useEffect(() => {
      //SETTINGS LOADED.
      //IF THE USER IS LOGGED IN THEN WE NEED TO GET THE USER DATA AND POP HIM TO HOME PAGE
      //OTHERWISE WE NEED TO SHOW THE LOGIN PAGE
      if (countriesData !== null) {
        if (General.isLoggedIn()){
            setCountriesLoaded(true); 
            // getUserData();
        }  else {
          setCountriesLoaded(true); 
        }
      } 
  }, [countriesData]);

     
    useEffect(() => {
      if (userInfoData !== null) {
        if (userInfoData.status){
           setUserAuth(true);
           if (props.path == "/login") {
            window.location.replace('/app');
           }
        } else {
           General.logout();
        }
        setUserDataLoaded(true); 
        // dispatch({type: POST_USER_INFO, payload: null});
      } 
  }, [userInfoData]);



    // const getUserData = () => {
    //   let userId = Store.getData(USER_ID_SET);
    //   let postData = {userId: userId};
    //   axios.defaults.headers.common['Authorization'] = "Bearer " + Store.getData(USER_TOKEN_SET);
    //   dispatch(postUserInfo(postData));
    // }
    

    if (!settingsLoaded || !countriesLoaded){   
        return <Loading/>;
    } else {
      if (!props.path.includes("verify-email") && 
          !props.path.includes("change-password") && 
          !props.path.includes("privacy")) {
             console.log("NAVIGATE_HOME",props.path);
            if (props.path != "" && props.path != "/" ){
              return (<Navigate to="/"/>);
            }
            return props.children;
          } else {
             console.log("NAVIGATE_CHILDREN");
            return props.children;
          }

      // if (General.isLoggedIn()) {
      //   //IF THE USER IS LOGGED IN WE HAVE TO WAIT FOR THE USER DATA ALSO
      //   if (userDataLoaded && !userAuth){
      //     return (<Navigate to="/login"/>);
      //   }else if (props.path == "/login") {
      //     return <Loading/>;
      //   } else {
      //     if (!userDataLoaded){
      //       return <Loading/>;
      //     }
      //     return props.children;
      //   }
        
      // } else {
      //   if (props.path != "/login"){
      //     return (<Navigate to="/login"/>);
      //   } else {
      //     return props.children;
      //   }
      // }
    }

   
}

export default PrivateRoute;