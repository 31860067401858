//SETTINGS
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_COUNTRIES = "GET_COUNTRIES";


//USER
export const USER_TOKEN_SET = "USER_TOKEN_SET";
export const USER_ID_SET = "USER_ID_SET";
export const POST_SIGN_IN = "POST_SIGN_IN";
export const POST_SIGN_OUT = "POST_SIGN_OUT";
export const POST_USER_INFO = "POST_USER_INFO";
export const POST_CHANGE_PASSWORD = "POST_CHANGE_PASSWORD";
export const POST_VERIFY_EMAIL = "POST_VERIFY_EMAIL";

//USERS
export const POST_USERS_ALL = "POST_USERS_ALL";
export const POST_USERS_ADD = "POST_USERS_ADD";
export const POST_USERS_EDIT = "POST_USERS_EDIT";
export const POST_USERS_CHANGE_APPROVED = "POST_USERS_CHANGE_APPROVED";

//CATEGORIES
export const POST_CATEGORIES_ALL = "POST_CATEGORIES_ALL";
export const POST_CATEGORIES_ADD = "POST_CATEGORIES_ADD";
export const POST_CATEGORIES_EDIT = "POST_CATEGORIES_EDIT";
export const POST_CATEGORIES_DELETE = "POST_CATEGORIES_DELETE";

//SOURCES
export const POST_SOURCES_ALL = "POST_SOURCES_ALL";
export const POST_SOURCES_ADD = "POST_SOURCES_ADD";
export const POST_SOURCES_EDIT = "POST_SOURCES_EDIT";
export const POST_SOURCES_DELETE = "POST_SOURCES_DELETE";
export const POST_SOURCES_REMOVE_ICON = "POST_SOURCES_REMOVE_ICON";

//UPLOAD
export const POST_UPLOAD_IMAGE = "POST_UPLOAD_IMAGE";

//TAGS
export const POST_TAGS_ALL = "POST_TAGS_ALL";
export const POST_TAGS_ALL_PICK = "POST_TAGS_ALL_PICK";
export const POST_TAGS_ADD = "POST_TAGS_ADD";
export const POST_TAGS_EDIT = "POST_TAGS_EDIT";
export const POST_TAGS_DELETE = "POST_TAGS_DELETE";
export const POST_TAGS_REMOVE_EVERYWHERE = "POST_TAGS_REMOVE_EVERYWHERE";

//POSTS
export const POST_POSTS_ALL = "POST_POSTS_ALL";
export const POST_POSTS_ADD = "POST_POSTS_ADD";
export const POST_POSTS_EDIT = "POST_POSTS_EDIT";
export const POST_POSTS_DELETE = "POST_POSTS_DELETE";

//SOCIAL TEMPLATE
export const POST_SOCIAL_TEMPLATE_ADD = "POST_SOCIAL_TEMPLATE_ADD";
export const POST_SOCIAL_TEMPLATE_ALL = "POST_SOCIAL_TEMPLATE_ALL";
export const POST_SOCIAL_TEMPLATE_EDIT = "POST_SOCIAL_TEMPLATE_EDIT";
export const POST_SOCIAL_TEMPLATE_DELETE = "POST_SOCIAL_TEMPLATE_DELETE";